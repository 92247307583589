<template>
	<div class="form-group">
		<h3
			v-if="title"
			class="form-group__header form-group__header--padding z-subheading z-subheading--spaced"
		>
			{{ title }} text
		</h3>

		<div class="form-group__item form-grid">
			<StyleSelectControl
				:element="element"
				:property="PROPERTY_FONT_FAMILY"
				:style-options="availableFontTypeSelection"
				label="Font"
				@update:current="checkForUnavailableFontWeights"
			/>
			<StyleSelectControl
				:element="element"
				:property="PROPERTY_FONT_WEIGHT"
				:style-options="availableFontWeightSelection"
				label="Weight"
			/>
		</div>

		<div class="form-group__item form-grid form-grid--3">
			<StylePropertyControl
				:element="element"
				:property="PROPERTY_FONT_SIZE"
				label="Size"
			/>
			<StylePropertyControl
				v-if="isMobileFontSize"
				:element="element"
				:property="PROPERTY_M_FONT_SIZE"
				label="Mobile size"
			/>
			<StylePropertyControl
				:element="element"
				:property="PROPERTY_LINE_HEIGHT"
				label="Line height"
				unit=""
			/>
			<StylePropertyControl
				:element="element"
				:property="PROPERTY_LETTER_SPACING"
				label="Spacing"
				unit="em"
			/>
		</div>

		<div class="form-group__item form-grid">
			<div>
				<ZyroLabel
					class="form-group__item--label"
					:bold="false"
				>
					Other options
				</ZyroLabel>
				<StyleToggleControl
					:element="element"
					:property="PROPERTY_TEXT_DECORATION"
					toggle-value="underline"
				/>
				<StyleToggleControl
					:element="element"
					:property="PROPERTY_TEXT_TRANSFORM"
					toggle-value="uppercase"
				/>
			</div>
		</div>

		<div class="form-group__item form-grid">
			<StyleColorControl
				:element="element"
				:property="PROPERTY_COLOR"
				label="Color"
			/>
		</div>
	</div>
</template>

<script>
import {
	mapMutations,
	mapState,
	mapGetters,
} from 'vuex';

import StyleColorControl from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/StyleColorControl.vue';
import StylePropertyControl
	from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/StylePropertyControl.vue';
import StyleSelectControl from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/StyleSelectControl.vue';
import StyleToggleControl from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/StyleToggleControl.vue';
import {
	PROPERTY_FONT_SIZE,
	PROPERTY_M_FONT_SIZE,
	PROPERTY_FONT_FAMILY,
	PROPERTY_FONT_STYLE,
	PROPERTY_FONT_WEIGHT,
	PROPERTY_LINE_HEIGHT,
	PROPERTY_COLOR,
	PROPERTY_TEXT_DECORATION,
	PROPERTY_TEXT_TRANSFORM,
	PROPERTY_LETTER_SPACING,
} from '@/constants/globalStyles';
import { getClosestNumberInArray } from '@/utils/array';
import {
	convertWeightStringToNumber,
	extractFontTypeFromVariable,
	transformFontTypeToVariable,
} from '@/utils/font';
import { capitalizeFirstLetter } from '@/utils/modifyString';

export default {
	components: {
		StyleColorControl,
		StyleSelectControl,
		StyleToggleControl,
		StylePropertyControl,
	},
	props: {
		element: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			default: null,
			required: false,
		},
		isMobileFontSize: {
			type: Boolean,
			default: true,
		},
	},
	setup() {
		return {
			PROPERTY_FONT_SIZE,
			PROPERTY_M_FONT_SIZE,
			PROPERTY_FONT_FAMILY,
			PROPERTY_FONT_STYLE,
			PROPERTY_FONT_WEIGHT,
			PROPERTY_LINE_HEIGHT,
			PROPERTY_COLOR,
			PROPERTY_TEXT_DECORATION,
			PROPERTY_TEXT_TRANSFORM,
			PROPERTY_LETTER_SPACING,
		};
	},
	computed: {
		...mapState(['website']),
		...mapGetters('fonts', ['getAvailableFontWeights']),
		availableFontWeightSelection() {
			return this.availableFontWeights
				.map((fontWeight) => ({
					title: fontWeight,
					value: fontWeight,
				}));
		},
		availableFontTypeSelection() {
			const { font } = this.website.styles;

			return Object.keys(font).map((key) => ({
				title: capitalizeFirstLetter(key),
				value: transformFontTypeToVariable(key),
			}));
		},
		availableFontWeights() {
			const { styles } = this.website;
			const fontType = extractFontTypeFromVariable(styles[this.element][PROPERTY_FONT_FAMILY]);

			return this.getAvailableFontWeights[fontType];
		},
	},
	mounted() {
		/**
		 * This should not be needed but might prevent a bug
		 */
		this.checkForUnavailableFontWeights();
	},
	methods: {
		...mapMutations([
			'setStyleProperty',
			'setWebsiteMeta',
		]),
		checkForUnavailableFontWeights() {
			const { styles } = this.website;
			const elementFontWeight = convertWeightStringToNumber(
				styles[this.element][PROPERTY_FONT_WEIGHT],
			);
			const isElementFontWeightUnavailable = !this.availableFontWeights.includes(elementFontWeight);

			if (isElementFontWeightUnavailable) {
				this.setClosestFontWeight(elementFontWeight);
			}
		},
		setClosestFontWeight(elementFontWeight) {
			const closestAvailableFontWeight = getClosestNumberInArray(
				this.availableFontWeights, elementFontWeight,
			);

			this.setStyleProperty({
				element: this.element,
				property: PROPERTY_FONT_WEIGHT,
				value: closestAvailableFontWeight,
			});
		},
	},
};
</script>

<style scoped lang="scss">
@import '@/components/builder-drawers/drawers/partials/_forms.scss';
</style>
